export const environment = {
  production: true,
  baseApiUrl: 'https://paymentportalapitest.azurewebsites.net',
  appInsights: {
    instrumentationKey: '0e63f1fd-a95a-49a5-a298-998f9b1d6f21'
  },
  context: 'https://pei.h.redlink.com.ar',
  portalId:2,
  title:'Expensas Pagas <strong>Online</strong>',
  isTestMode: true
};
